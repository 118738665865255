export class TinymceOptions {
  /** æå®tinymceç®å½è·¯å¾ï¼é»è®¤ï¼`./assets/tinymce/` */
  baseURL ? = './assets/tinymce/';
  /** æå®tinymceæä»¶åï¼é»è®¤ï¼`tinymce.min.js` */
  fileName ? = 'tinymce.min.js';
  /** é»è®¤éç½®é¡¹ï¼å¯¹å¨å± Tinymce ææ */
  config?: any;
  /** å»¶è¿å è½½ï¼åä½ï¼æ¯«ç§ï¼ï¼é»è®¤ï¼`0` */
  delay?: number;
}
